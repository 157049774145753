<template>
  <div class="preview-box">
    <div class="preview-box-inner landscape">
      <a
        :href="`https://api.riggott-ims.co.uk/liveJobs/jobCardPdf/${job.id}.pdf`"
        target="_blank">
        <v-icon
          title="Generate PDF"
          class="no-print pdf-preview"
        >mdi-file-pdf-box</v-icon>
      </a>
      <v-icon
        class="no-print print-preview"
        @click="print()"
      >mdi-printer</v-icon>
      <v-icon
        class="no-print close-preview"
        @click="goBack()"
      >mdi-close-circle</v-icon>
      <div class="preview-inner pa-2">
        <v-row class="pt-10">
          <v-col class="col12">
            <h1>Job number: {{ job.jobNumber }}</h1>
            <table class="job-card-table">
                <thead>
                <tr>
                    <td class="cell-border-top cell-border-left">Client Name:</td>
                    <td class="cell-border-top cell-border-bottom cell-border-left">PO: {{ job.purchase_order }}</td>
                    <td class="cell-border-top cell-border-left">Client Tel: {{ job.customerTel }}</td>
                    <td class="cell-border-top cell-border-right">Riggotts Contact: {{ job.quotedBy }}</td>
                </tr>
                <tr>
                    <td class="cell-border-bottom cell-border-left">{{ job.customer }}</td>
                    <td class="cell-border-bottom cell-border-left">Customer Job Ref: {{ job.customerReference }}</td>
                    <td class="cell-border-left">Contact: {{ job.contact }}</td>
                    <td class="cell-border-right cell-border-left">Site contact: {{ job.site_contact }}</td>
                </tr>
                <tr>
                    <td class="cell-border-left">Site Name:</td>
                    <td>Distance (One way) = {{ job.distance }} miles</td>
                    <td class="cell-border-left cell-border-bottom">Contact Tel: {{ job.contactTel }}</td>
                    <td class="cell-border-bottom cell-border-right cell-border-left">Site Tel: {{ job.site_tel }} </td>
                </tr>
                <tr>
                    <td class="cell-border-left cell-border-bottom cell-border-right" colspan="4">{{ job.site }}</td>
                </tr>
                <tr>
                    <td class="cell-border-bottom cell-border-left cell-border-right">
                        Start Date:
                    </td>
                    <td class="cell-border-bottom cell-border-right">
                        Start Time:
                    </td>
                    <td class="cell-border-bottom cell-border-right">Site Post Code: {{ job.postCode }}</td>
                    <td class="cell-border-bottom cell-border-right">Site Email: {{ job.site_email }}</td>
                </tr>
                <tr>
                    <td class="cell-border-bottom cell-border-left">Description of works and materials:</td>
                    <td class="cell-border-bottom">Surface: {{ job.surfaceType }}</td>
                    <td class="cell-border-bottom cell-border-left">Special Instructions: </td>
                    <td class="cell-border-bottom cell-border-right"><strong>No of Visits Estimated: {{ job.days }}</strong></td>
                </tr>
                <tr>
                    <td class="cell-border-bottom cell-border-left cell-fill-yellow">
                        <div v-html="job.description"></div>
                    </td>
                    <td class="cell-border-bottom">
                        Miles to Site (one-way) <span class="float-right">{{ job.distance }}</span><br />
                        Drive Time to Site <span class="float-right">{{ job.travel_hours }}</span><br />
                        Hours for Job <span class="float-right">{{ job.work_hours }}</span>
                    </td>
                    <td class="cell-border-bottom cell-border-left cell-border-right cell-fill-yellow" colspan="2">
                        <div v-html="job.special_instructions"></div>
                    </td>
                </tr>
                <tr>
                  <td class="no-pad-cell" colspan="4">
                    <table class="boq-table">
                      <thead>
                        <tr>
                          <th style="width: 60%" class="cell-border-bottom cell-border-left text-left">Bill of Quants:</th>
                          <th style="width: 20%" class="cell-border-bottom cell-border-left text-left">Units</th>
                          <th style="width: 20%" class="cell-border-bottom cell-border-left cell-border-right text-left">Qty</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(section, sindex) in job.sections" :key="sindex">
                          <td style="padding:0" colspan="3">
                            <table style="width:100%;border-collapse:collapse">
                              <thead>
                                <tr>
                                  <th class="cell-border-left cell-border-right cell-border-bottom text-left" colspan="3">
                                    {{ section.title }}
                                  </th>
                                </tr>
                                <tr v-for="(line, index) in section.lines" :key="index">
                                  <td style="width: 60%" class="cell-border-left">
                                      {{ line.boq }} {{ line.colour }} {{ line.material }}
                                  </td>
                                  <td style="width:20%" class="cell-border-left">{{ line.unit }}</td>
                                  <td style="width:20%" class="cell-border-left cell-border-right">{{ line.quantity }}</td>
                                </tr>
                              </thead>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                </thead>
                <tbody>
                  <tr>
                      <td class="cell-border-bottom cell-border-left cell-border-top cell-border-right" colspan="4">
                          <strong class="text-red">
                              Please note! Any work undertaken over and above the items detailed in the BOQ MUST BE APPROVED BY Riggotts!
                          </strong>
                      </td>
                  </tr>
                </tbody>
            </table>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'LiveJobCardPrint',
  data() {
    return {
      job: {},
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  methods: {
    goBack() {
      this.$router.go('-1');
    },
    print() {
      window.print();
    },
    getJob() {
      axios.get(`/liveJobs/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.job = response.data;
      });
    },
  },
  mounted() {
    this.getJob();
  },
};
</script>
